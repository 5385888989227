<template>
  <t-page>
    <template #header>
      <h3>{{ $t('pages.organization.pings.heading') }}</h3>
    </template>
    <t-grid>
      <t-grid-cell>
        <index-table :query="query" :fields="fields" />
      </t-grid-cell>
    </t-grid>
  </t-page>
</template>

<script>
import Vue from 'vue';
import Ping from '~/models/Ping';
import IndexTable from '~/pages/shared/pings/partials/IndexTable';

export default Vue.extend({
  components: { IndexTable },

  middleware: 'tenantmember',

  data: () => ({
    fields: ['sent_at', 'applicant_occupation_name'],
  }),

  methods: {
    query() {
      return new Ping()
        .include('applicant.occupation')
        .where('organization_id', this.$route.params.id);
    },
  },
});
</script>
