<template>
  <registration-codes-index :query="query" :fields="fields" :filters="filters" />
</template>

<script>
import Vue from 'vue';
import RegistrationCode from '~/models/RegistrationCode';
import RegistrationCodesIndex from '~/pages/shared/registration-codes/RegistrationCodesIndex';

export default Vue.extend({

  components: { RegistrationCodesIndex },
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    query: null,

    filters: [],

    fields: ['label', 'url', 'occupation', 'state', 'created_at', 'use_count'],
  }),

  fetch() {
    const query = new RegistrationCode();

    if (this.organization.id) {
      query.where('organization_id', this.organization.id);
    }

    this.query = () => query.include(['organization', 'occupation']);
  },
});
</script>
